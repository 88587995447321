<template>
  <div class="bg-gray-900 text-white min-h-screen flex flex-col">
    <header class="flex justify-between items-center p-4">
      <div class="text-xl font-bold">yally</div>
      <nav>
        <a href="/sign-in" class="text-sm">Sign In</a>
      </nav>
    </header>

    <main class="flex-grow flex flex-col justify-center items-center px-4">
      <div class="text-center max-w-2xl mx-auto">
        <h1 class="text-5xl font-bold mb-4">
          Supercharge your
          <span class="bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text text-transparent">Community</span>
        </h1>
        <p class="text-xl mb-4">
          Get ready for the next generation of event hosting.
        </p>
        <div class="space-y-4 mb-8">
          <p class="text-lg text-gray-300">
            🎉 Create stunning event pages in minutes
          </p>
          <p class="text-lg text-gray-300">
            🎟️ Seamlessly manage tickets and RSVPs
          </p>
          <p class="text-lg text-gray-300">
            🤝 Build your community, your way
          </p>
        </div>

        <p class="text-lg mb-8 text-pink-400">
          Be among the first to experience it.
        </p>

        <div class="flex flex-col sm:flex-row gap-4 justify-center max-w-md mx-auto">
          <input v-model="email" type="email" placeholder="Enter your email" :disabled="isLoading"
            class="px-4 py-3 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-pink-500 flex-grow">
          <button @click="handleSubmit" :disabled="isLoading"
            class="bg-gradient-to-r from-pink-500 to-blue-500 px-6 py-3 rounded-lg font-semibold hover:opacity-90 transition-opacity whitespace-nowrap disabled:opacity-50">
            {{ isLoading ? 'Joining...' : 'Join Waitlist' }}
          </button>
        </div>

        <!-- Success/Error Messages -->
        <p v-if="successMessage" class="mt-4 text-green-400">{{ successMessage }}</p>
        <p v-if="errorMessage" class="mt-4 text-red-400">{{ errorMessage }}</p>
      </div>
    </main>

    <footer class="mt-auto p-8">
      <div class="text-center text-sm text-gray-500">
        © 2024 yally. All rights reserved.
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const baseApiUrl = config.public.apiBaseUrl

const email = ref('')
const isLoading = ref(false)
const successMessage = ref('')
const errorMessage = ref('')

async function handleSubmit() {
  if (!email.value) {
    errorMessage.value = 'Please enter your email'
    return
  }

  try {
    isLoading.value = true
    errorMessage.value = ''
    successMessage.value = ''

    const response = await fetch(`${baseApiUrl}/waitlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value,
      }),
    })

    const data = await response.json()

    if (!response.ok) {
      throw new Error(data.message || 'Failed to join waitlist')
    }

    successMessage.value = data.message
    email.value = ''
  } catch (error) {
    errorMessage.value = error instanceof Error ? error.message : 'Something went wrong'
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped>
/* Add any additional styles here */
</style>